import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import {
  AboutWrapper,
  AboutPageTitle,
  AboutDetails,
  AboutImageWrapper,
  Author,
  Quote,
  Cite,
} from "./style"

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      aboutImage: file(absolutePath: { regex: "/annie-coole.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `)

  return (
    <AboutWrapper>
      <AboutImageWrapper>
        <Image fluid={Data.aboutImage.childImageSharp.fluid} />
      </AboutImageWrapper>
      <AboutDetails>
        <AboutPageTitle>
          <b>About Annie</b>
        </AboutPageTitle>

        <Quote>
          "The roots of resilience… are to be found in the sense of being
          understood by and existing in the mind and heart of a loving, attuned
          and self- possessed other."
        </Quote>

        <Cite>- Diana Fosha</Cite>

        <p>
          I am a BACP (British Association for Counselling and Psychotherapy)
          registered counsellor. I am accountable to their Ethical Framework of
          Counselling.
        </p>
        <p>
          I work in the private sector offering confidential, non-judgemental
          support and help to those in need of a calm space. I am committed to
          working with the individual and their specific needs, and offer a
          relationship based on empathy, non-judgement, and honesty. Most people
          who come to me are through my directory listing and website. They can
          stay long or short term.
        </p>

        <Quote>
          "There is no greater agony than bearing an untold story."
        </Quote>
        <Cite>- Maya Angelou</Cite>
        <p>
          We all have our own story to tell. Not just to tell our story but to
          have a significant ‘other’ who with empathy allows us to entire the
          process of ‘being known.’ Being known to oneself and to make sense of
          the dialogues in our own minds. I help people to find peace of mind by
          helpling them to manage their anxiety or low mood. I offer an
          opportunity for you to explore your concerns in a caring, confidential
          and trusting atmosphere. Life can be tough, full of problems and
          issues that we just cannot cope with anymore. Maybe turning to family
          or friends for help just is not an option. It may be that you want
          support to manage issues such as grief, anxiety and panic, stress and
          anger which can result in feelings of depression and low self-esteem.
          where we can lose focus and need help to move forwards. It can feel a
          little frightening acknowledging to yourself that you want or need to
          speak to a counsellor, so taking this first step can be an incredibly
          hard thing to do and I appreciate the effort it might have taken to
          get this far. Fear of loss are normal and integral aspects of life
          itself. Losses are often ignored and over a lifetime we experience an
          accumulation of losses. Loss is not something to 'get over'. comes in
          many forms. Loss is a common strand that thread through all aspects of
          life. I aim to provide a safe environment whereby you can process your
          loss and move towards resolution and purpose
        </p>
        <p>
          I support people through their life experiences such as bereavement,
          redundancy, pregnancy related issues, eating disorders, disability,
          health related issues, relationship problems, retirement, lack of
          self-confidence to enable them to enjoy life again. I can help process
          the above issues as well as help my clients take control of how they
          respond to certain situations.
        </p>
        <p>
          My aim is to work with and alongside you, creating an atmosphere where
          you feel relaxed and safe enough to begin facing your problems. In our
          sessions together we can explore your feelings and behaviours around
          the negativity affecting you and find a process, unique to you, in
          finding possible solutions to your issues and conflict. I am aware
          that many people do not want to be in counselling for the long term.
          Therefore, sessions can be tailored to busy lifestyle. Long-term
          face-to-face work, Short-term face-to-face work which are time-limited
        </p>
        <p>
          In the light of these extraordinary times where we are slowly emerging
          from this lockdown, I can help you to find ways to manage the effects
          of this strain. We can work together on developing your resources to
          get through the stress, anxiety and/or depression. You do not have to
          face this situation alone. During this current time I am offering
          consultations via Zoom or telephone.
        </p>
        <Author>
          <p>Annie Coole</p>
          <p>
            MBACP member (2020); Ad Dip in Counselling (2010) Cert in
            Counselling (2008); Online and Telephone Counselling Cert (2020) 
          </p>
        </Author>
      </AboutDetails>
    </AboutWrapper>
  )
}

export default About
