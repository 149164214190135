import styled from "styled-components"
import { themeGet } from "styled-system"

export const AboutWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 90px 0 120px 0;
  padding-left: 75px;
  padding-right: 75px;
  @media (min-width: 1550px) {
    width: 1500px;
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 1199px) {
    padding: 80px 35px 80px 35px;
  }
  @media (max-width: 575px) {
    padding: 60px 25px 60px 25px;
  }
`

export const AboutImageWrapper = styled.div`

@media (max-width: 991px) {
  order: 1;
  margin-top: 40px;
  width: 100%;
}

  @media (min-width: 991px) {
    max-width: 42%;
    padding-right: 60px;
    flex: 0 0 42%;
    margin: 0px;
  }
`

export const AboutPageTitle = styled.h1`
  display: inline-block;
  font-size: 30px;
  font-weight: 700;
  color: ${themeGet("colors.textColor", "#292929")};
  line-height: 1.53;

  @media (max-width: 990px) {
    font-size: 26px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
  }

  &:after {
    content: "";
    height: 8px;
    width: 120px;
    display: block;
    margin: 18px 0;
    background: ${themeGet("colors.mainTheme")};
  }
`

export const AboutDetails = styled.div`
  @media (min-width: 991px) {
    max-width: 58%;
    flex: 0 0 58%;
    margin: 0px;
  }

  p {
    font-size: ${themeGet("fontSizes.4", "16")}px;
    margin-bottom: 2em;
  }

  h2 {
    font-size: 21px;
    font-weight: 500;
    color: ${themeGet("colors.textColor", "#292929")};
    margin-bottom: 40px;
    @media (max-width: 990px) {
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 25px;
    }
  }
`
export const SocialProfiles = styled.div`
  margin-top: 60px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 40px;
  }

  &:before {
    content: "";
    width: 30px;
    height: 1px;
    background: #292929;
    display: block;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
`
export const Author = styled.div`
  p {
    font-size: ${themeGet("fontSizes.4", "16")}px;
    margin-bottom: 0;
  }
`

export const Quote = styled.blockquote`
    font-size: 18px;
margin-left: 0;
`

export const Cite = styled.cite`
    font-size: ${themeGet("fontSizes.4", "16")}px;
    font-family: 'Raleway',sans-serif;
    display: block;
margin-bottom: 2em;
`